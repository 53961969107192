<template>
    <el-container style="height: 100vh;">
        <el-header class="header-with-bg juzhong" style="height: 100px;">
            <div class="juzhong">
                <div><b-img :src="$store.state.systeminfo.systeminfo.logourl" alt="logo" /></div>
                <div>
                    <h2 class="brand-text mb-0 logoname">{{ $store.state.systeminfo.systeminfo.name }}</h2>
                </div>
            </div>
            <div>
                <h1 class="brand-text mb-0 logoname">就诊信息综合显示屏</h1>
            </div>
            <div class="time">
                <div>
                    <h3 class="brand-text mb-0 logoname">{{nowdate}}</h3>
                </div>
                <div>
                    <h3 class="brand-text mb-0 logoname">{{ nowTime }} {{ nowweek }}</h3>
                </div>
            </div>
        </el-header>
        <el-main class="custom-main">
            <div class="left-section">
                <div class="shangyuanjiao lefttopheight juzhong">
                    <div class="juzhong" v-if="visitnum !== ''">
                        <h4 class="brand-text mb-0 logoname ml-3">请{{ visitnum }}号</h4>
                        <h4 class="brand-text mb-0 name ml-3">{{ meetperson }}</h4>
                        <h4 class="brand-text mb-0 logoname ml-3">到诊室就诊</h4>
                    </div>
                </div>
                <div class="leftmiddleheight visitheader">
                    <div class="pingfen">诊号</div>
                    <div class="pingfen">姓名</div>
                    <div class="pingfen">就诊状态</div>
                </div>
                <div class="visitheight">
                    <transition-group name="fade">
                        <div v-for="(patient, index) in displayedPatients" :key="patient._id" :class="['leftbottomheight', 'visitheader',index % 2 === 0 ? 'blue-background' : 'alternate-row']">
                            <div class="pingfen">{{ lastFour(patient.mobile) }}</div>
                            <div class="pingfen">{{ patient.meetperson }}</div>
                            <div :class="['pingfen', patient.statusvisit === 'visiting' ? 'fontred' : '']">{{ patient.statusvisit === "waiting" ? "候诊中" : "就诊中" }}</div>
                        </div>
                    </transition-group>
                </div>
            </div>
            <div class="right-section ml-1 yuanjiao" ref="carousel">
                <el-carousel :height="carouselHeight" :interval="5000">
                    <el-carousel-item v-for="(item ,index) in bannerlist" :key="index">
                        <img class="yuanjiao" mode="heightFix" :src="item.imageUrl" style="height:100%; width:100%;"/>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </el-main>
        <el-footer class="juzhong" style="height: 60px;text-align: right;"><h3 class="brand-text mb-0 logoname">请保持安静的诊室环境，耐心等候，谢谢合作!</h3></el-footer>
    </el-container>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import socket from "@/utils/socket";
//import autofit from 'autofit.js';

import store from '@/store'

export default {
    components: {
        BLink,
        BImg,
    },
    setup() {
        // App Name
        const { appName, appLogoImage } = $themeConfig.app
        return {
            appName,
            appLogoImage,
        }
    },
    data() {
        return {
            nowTime: '',
            nowdate: '',
            nowweek: '',
            patients: [], // 所有患者的就诊数据
            displayedPatients: [], // 当前显示的患者数据
            currentIndex: 0, // 当前显示的数据索引
            setvisit: [],
            visitnum: "",
            meetperson: "",
            carouselHeight: null,
            bannerlist: []
        };
    },
    mounted() {
        // autofit.init({
        //     dh: 1080,
        //     dw: 1920,
        //     el:"#app",
        //     resize: true,
        //     },
        //     false
        // )
        let carousel_height = window.getComputedStyle(this.$refs.carousel).height;
        // 赋值给 el-carousel中height动态绑定的carouselHeight
        this.carouselHeight = carousel_height
        this.getNowTime();
        socket.on('CallStation', (data) => {
            this.visitnum = data.visitnum
            this.meetperson = data.meetperson
            this.loadItems();
            console.log('Received message from server')
            const voice = "请" + this.visitnum + "号" + this.meetperson + "到诊室就诊"
            this.delaySpeak(voice)
            console.log('说出来');
        });
        socket.on('updatavisit', () => {
            this.visitnum = ""
            this.meetperson = ""
            this.loadItems();
        });
    },
    created() {
        this.loadItems();
        this.indexbanner();
        // 每隔4秒切换显示的数据
        setInterval(this.rotatePatients, 5000);
    },
    beforeDestroy(){
        //autofit.off()
    },
    methods: {
        say(data){
            this.speak(data)
        },
        lastFour(data){
            return data.slice(-4);
        },
        delaySpeak(sentence) {
            let count = 0;
            const utterance = new SpeechSynthesisUtterance(sentence)
            const intervalId = setInterval(() => {
                window.speechSynthesis.speak(utterance) 
                count++;
                if (count === 3) {
                clearInterval(intervalId);
                }
            }, 3000);
        },
        speak(sentence) { 
            // 生成需要语音朗读的内容 
            const utterance = new SpeechSynthesisUtterance(sentence)
            console.log(sentence);
            // 由浏览器发起语音朗读的请求 
            window.speechSynthesis.speak(utterance) 
        },
        sortData(data) {
            const sortedData = data.filter(item => item.signin && item.statusvisit === "waiting");
            const visitingData = data.filter(item => item.signin && item.statusvisit === "visiting");
            sortedData.sort((a, b) => {
                const aTime = new Date(`1970-01-01T${a.products.firsttime}:00Z`).getTime();
                const bTime = new Date(`1970-01-01T${b.products.firsttime}:00Z`).getTime();
                if (aTime !== bTime) {
                return aTime - bTime;
                }
                return a.updatedAt - b.updatedAt;
            });
            return visitingData.concat(sortedData, data.filter(item => !item.signin || (item.statusvisit !== "waiting" && item.statusvisit !== "visiting")));
        },
        fetchPatients() {
            // 模拟异步获取患者就诊数据
            // 这里假设将患者数据赋值给this.patients
            this.patients = this.setvisit;
            console.log("1111",this.setvisit)
            console.log("2222",this.patients)
            // 初始化显示的数据
            this.displayedPatients = this.patients.slice(0, 7);
        },
        rotatePatients() {
            // 计算下一组要显示的数据索引范围
            const start = this.currentIndex;
            const end = start + 7;
            // 根据索引范围获取要显示的数据
            this.displayedPatients = this.patients.slice(start, end);
            // 更新当前索引
            this.currentIndex = end >= this.patients.length ? 0 : end;
        },
        getweek(){
            var d = new Date().getDay();
            let day;
            if (d == 0) {
                day = "日";
            } else if (d == 1) {
                day = "一";
            } else if (d == 2) {
                day = "二";
            } else if (d == 3) {
                day = "三";
            } else if (d == 4) {
                day = "四";
            } else if (d == 5) {
                day = "五";
            } else if (d == 6) {
                day = "六";
            }
            return "星期" + day
        },
        getNowTime() {
            let speed = 1000
            let that = this
            let theNowTime = function () {
                that.nowTime = that.timeNumber()
                that.nowdate = that.dateNumber()
                that.nowweek = that.getweek()
            }
            setInterval(theNowTime, speed)
        },
        timeNumber() {
            let today = new Date()
            let date = today.getFullYear() + '-' + this.twoDigits(today.getMonth() + 1) + '-' + this.twoDigits(today.getDate())
            return date
        },
        dateNumber() {
            let today = new Date()
            let time = this.twoDigits(today.getHours()) + ':' + this.twoDigits(today.getMinutes()) + ':' + this.twoDigits(today.getSeconds())
            return time
        },
        twoDigits(val) {
            if (val < 10) return '0' + val
            return val
        },
        loadItems() {
            this.$api.visit.callstation().then((res) => {
                this.setvisit = this.sortData(res.data.data)
                this.fetchPatients();
                console.log("3333",this.patients)
            });
        },
        indexbanner(){
            this.$api.banner.otherbanner().then((res) => {
                this.bannerlist = res.data.data
            });
        }
    },
}
</script>

<style>
.fontred{
    color: red;
}
.visitheight{
    height:560px;
    overflow: hidden; /* 隐藏任何溢出内容 */
}
.blue-background {
  background-color: #5178cf;
  color:#fff;
  font-size:1rem;
  font-weight: 600;
}

.alternate-row {
  background-color: #ffffff;
  color:#000000;
  font-size:1rem;
  font-weight: 600;
}
.pingfen{
    flex: 1;
    text-align: center;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.patient-card {
  background-color: #f2f2f2;
  padding: 10px;
  margin-bottom: 10px;
}
.visitheader{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 3rem;
}
.lefttopheight{
    height:100px;
    background-color:#5178cf
}
.leftmiddleheight{
    height:80px;
    background-color:#ffffff
}
.leftbottomheight{
    height:80px;
    overflow: hidden; /* 隐藏任何溢出内容 */
}
.shangyuanjiao{
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.yuanjiao{
    border-radius: 6px;
}
.custom-main {
  display: flex;
  flex-direction: row;
}

.left-section {
  flex-basis: 70%;
}

.right-section {
  flex-basis: 30%;
}
.testright{
    text-align: right;
}
h1 {
    font-size: 4rem;
}

h2 {
    font-size: 3rem;
}
h3 {
    font-size: 2rem;
}
h4 {
    font-size: 4rem;
}
.time{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.logoname {
    font-family: inherit;
    font-weight: 700;
    line-height: 1.2;
    color: #ffffff;
}

.name {
    font-family: inherit;
    font-weight: 700;
    line-height: 1.2;
    color: #000000;
}

.juzhong {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-with-bg {
    background-image: url('https://case.80000e.com/banner/headerbg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.el-footer {
    background-image: url('https://case.80000e.com/banner/footerbg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    line-height: 60px;
}

.el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
}

body>.el-container {
    margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
    line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
    line-height: 320px;
}</style>